<template>
    <div v-if="isAllowedTo('SupplierCentral/getProductConfigurationById')" class="full-height-layout fill">
        <router-view ref="routeview" class="full-height-layout container" :product-data-promise="productDataPromise"></router-view>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
export default {
    name: 'SupplierProductView',
    data () {
        return {
            breadcrumbElement: {
                name: '...',
            },
            productDataPromise: null,
        }
    },

    computed: {
        productConfigurationId () {
            return this.$route.params.productConfigurationId
        },

        resource () {
            const supplierId = this.supplierId
            const productConfigurationId = this.$route.params.productConfigurationId

            const resource = this.$store.state.products.products[`${supplierId}_${productConfigurationId}`]

            if (resource) {
                return resource
            }

            return null
        },
    },

    watch: {
        'resource' (value) {
            if (!value) {
                return
            }

            this.breadcrumbElement.name = value.productConfigurationName
            this.productStatus = value.productStatus || 'ACTIVE'
        },
    },
    created () {
        this.productDataPromise = this.$store.dispatch('products/getProductDetailsForSupplierAndProductConfigurationId', {
            supplierId: this.supplierId,
            productConfigurationId: this.$route.params.productConfigurationId,
        })
    },
}
</script>
